import { useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"
import { RenderTarget } from "framer"

function UncodeRedirect({ targetUrl, delayInSeconds }) {
    useEffect(() => {
        if (
            RenderTarget.current() === RenderTarget.preview ||
            RenderTarget.current() === RenderTarget.publish
        ) {
            const currentUrl = window.location.href
            if (currentUrl !== targetUrl) {
                const timer = setTimeout(() => {
                    window.location.href = targetUrl
                }, delayInSeconds * 1000)

                return () => clearTimeout(timer)
            }
        }
    }, [targetUrl, delayInSeconds])

    return <div style={{ width: "1px", height: "1px", opacity: 0 }}></div>
}

addPropertyControls(UncodeRedirect, {
    targetUrl: {
        type: ControlType.Link,
        title: "Target URL",
        defaultValue: "",
        placeholder: "https://example.com",
        description: "URL para redirecionamento se não for igual à atual.",
    },
    delayInSeconds: {
        type: ControlType.Number,
        title: "Delay (s)",
        defaultValue: 0,
        min: 0,
        unit: "s",
        description: "Tempo de atraso antes do redirecionamento.",
    },
    autor: {
        type: ControlType.String,
        title: "Autor",
        defaultValue: "Autor:",
        description: "Code by UncodeStack and @omatusaelhorch.",
    },
})

export default UncodeRedirect

/*
  Code by @omatusaelhorch
*/
